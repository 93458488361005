const loki = require("lokijs");
const LokiIndexedAdapter = require("lokijs/src/loki-indexed-adapter.js");

let localDB = null;
export const getDb = (initializeCallback) => {
  const idbAdapter = new LokiIndexedAdapter();
  const db = new loki("sonic.db", {
    adapter: idbAdapter,
    autoload: true,
    autoloadCallback: initializeCallback,
    autosave: true,
    autosaveInterval: 4000,
  });
  localDB = db;
  return db;
};

export async function dbInitialize(db = localDB) {
  // if database did not exist it will be empty so I will intitialize here
  upsertCol("podcasts", { indices: ["feedUrl"] });
  upsertCol("episodes", { indices: ["feedUrl", "podcastId"] });
  const podcastsCol = db.getCollection("podcasts");
  podcastsCol.insert({ title: "a custom podcast", feedUrl: "new url" });
}

export function upsertCol(db = localDB, colName, dbConfig) {
  let col = db.getCollection(colName);
  if (col === null) {
    col = db.addCollection(colName, dbConfig);
  }
}
