import { getRSSData } from "../services/rssService";
import { rssApi } from "../services/rssApi";
import { getPodcasts, insertPodcast } from "../services/podcast";
import { getHash } from "../utils";
import { getDb, dbInitialize } from "../services/database";

import * as _ from "lodash";
const initialState = {
  value: 0,
  podcasts: {}, // list of podcasts
  podcastFeeds: [],
  playlist: [],
  currentEpisode: null,
  searchQuery: "",
  currentPodcast: {},
  currentPodcastHash: "",
  podcastSearchItems: {
    results: [],
    resultsCount: 0,
  },
  podcastSearchText: "",
  dbPodcasts: [],
  // db: db,
};
export default {
  namespace: "podcast",
  state: { ...initialState },
  reducers: {
    setPodcasts(state, { podcasts }) {
      return { ...state, podcasts };
    },
    setDbPodcasts(state, { dbPodcasts }) {
      return { ...state, dbPodcasts };
    },
    addPodcast(state, { newPodcast, feedUrl }) {
      let newPodcasts = {
        ...state.podcasts,
        [feedUrl]: { ...newPodcast, lastUpdatedAt: new Date().toISOString() },
      };
      let newFeeds = [...state.podcastFeeds, feedUrl];
      // insertPodcast(state.db, newPodcast, feedUrl);
      return { ...state, podcasts: newPodcasts, podcastFeeds: newFeeds };
    },
    deletePodcast(state, { feedUrl }) {
      let newPodcasts = { ...state.podcasts };
      delete newPodcasts[feedUrl];
      let newFeeds = [...state.podcastFeeds.filter((feed) => feed !== feedUrl)];
      return { ...state, podcasts: newPodcasts, podcastFeeds: newFeeds };
    },
    addToPlaylist(state, { episode }) {
      // check if already in playlist

      return { ...state, playlist: [...state.playlist, episode] };
    },
    removeFromPlaylist(state, { playlistIndex }) {
      let newPlaylist = state.playlist;
      newPlaylist.splice(playlistIndex, 1);
      return { ...state, playlist: newPlaylist };
    },
    setCurrentEpisode(state, { playlistEpisodeId, podcastImage }) {
      return {
        ...state,
        currentEpisode:
          playlistEpisodeId < 0 ? state.playlist.length - 1 : playlistEpisodeId,
      };
    },
    setCurrentPodcast(state, { podcastData, feedUrl }) {
      return {
        ...state,
        currentPodcast: {
          ...podcastData,
        },
        currentPodcastHash: getHash(feedUrl),
      };
    },
    updatePodcast(state, { podcastData, feedUrl }) {
      if (state.podcast.podcasts[feedUrl]) {
        let newPodcasts = {
          ...state.podcasts,
          [feedUrl]: {
            ...podcastData,
            lastUpdatedAt: new Date().toISOString(),
          },
        };
        let newFeeds = [...state.podcastFeeds, feedUrl];
        return {
          ...state,
          currentPodcast: podcastData,
          currentPodcastHash: getHash(feedUrl),
          podcasts: newPodcasts,
          podcastFeeds: newFeeds,
        };
      } else {
        return {
          ...state,
          currentPodcast: podcastData,
          currentPodcastHash: getHash(feedUrl),
        };
      }
    },
    returnSearchPodcast(state, { podcastSearchItems, podcastSearchText }) {
      return {
        ...state,
        podcastSearchItems,
        podcastSearchText,
      };
    },
    clearPlaylist(state) {
      return {
        ...state,
        playlist: [],
      };
    },
  },
  effects: {
    *initPodcasts(_, { call, put, select }) {
      const { podcastFeeds, db } = yield select((state) => state.podcast);
      let podcasts = {};
      for (let index = 0; index < podcastFeeds.length; index++) {
        const podcastData = yield call(getRSSData, podcastFeeds[index]);
        podcasts[podcastFeeds[index]] = {
          ...podcastData,
          lastUpdatedAt: new Date().toISOString(),
        };
      }
      // const dbPodcasts = yield call(db, getPodcasts);
      // yield put({ type: "setDbPodcasts", dbPodcasts });
      yield put({ type: "setPodcasts", podcasts });
    },
    *addNewPodcast({ feed }, { call, put }) {
      const podcastData = yield call(getRSSData, feed);
      yield put({ type: "addPodcast", newPodcast: podcastData, feedUrl: feed });
    },
    *removePodcast({ feed }, { call, put }) {
      yield put({ type: "deletePodcast", feedUrl: feed });
    },
    *addEpisodeToPlaylist({ episode }, { call, put }) {
      yield put({ type: "addToPlaylist", episode });
    },
    *removeEpisodeFromPlaylist({ playlistIndex }, { call, put }) {
      yield put({ type: "removeFromPlaylist", playlistIndex });
    },
    *playEpisode({ episode }, { call, put, select }) {
      const { playlist } = yield select((state) => state.podcast);

      const playlistIndex = _.findIndex(
        playlist,
        (epi) => epi.title === episode.title && episode.pubDate === epi.pubDate
      );
      if (playlistIndex === -1) {
        yield put({ type: "addToPlaylist", episode });
      }
      yield put({
        type: "setCurrentEpisode",
        playlistEpisodeId: playlistIndex,
      });
    },
    *searchPodcasts({ searchQuery }, { call, put }) {
      const podcastList = yield call(rssApi, searchQuery);
      yield put({
        type: "returnSearchPodcast",
        podcastSearchItems: podcastList,
        podcastSearchText: searchQuery,
      });
    },
    *browsePodcast({ feedUrl }, { call, put, select }) {
      const { podcasts, podcastFeeds } = yield select((state) => state.podcast);
      if (podcasts[feedUrl]) {
        yield put({
          type: "setCurrentPodcast",
          podcastData: podcasts[feedUrl],
          feedUrl,
        });
      } else {
        const podcastData = yield call(getRSSData, feedUrl);
        yield put({
          type: "setCurrentPodcast",
          podcastData: {
            ...podcastData,
            lastUpdatedAt: new Date().toISOString(),
          },
          feedUrl,
        });
      }
    },
    *refreshPodcast({ feedUrl }, { call, put }) {
      const podcastData = yield call(getRSSData, feedUrl);
      yield put({
        type: "updatePodcast",
        podcastData,
        feedUrl,
      });
    },
    *clearSearchPodcasts(_, { call, put }) {
      yield put({
        type: "returnSearchPodcast",
        podcastSearchItems: {
          results: [],
          resultsCount: 0,
        },
        podcastSearchText: "",
      });
    },
    *cleanPlaylist(_, { call, put }) {
      yield put({
        type: "clearPlaylist",
      });
    },
  },
};
