import { db, dbInitialize } from "../services/database";

const initialState = {
  theme: "light",
  searchText: "",
};

export default {
  namespace: "ui",
  state: { ...initialState },
  reducers: {
    saveSearchText(state, { searchText }) {
      return { ...state, searchText };
    },
    saveTheme(state, { theme }) {
      return { ...state, theme };
    },
  },
  effects: {
    *initialize({}, { call, put }) {
      yield put({
        type: "podcast/initPodcasts",
      });
    },
    *setSearchText({ searchText }, { put }) {
      yield put({
        type: "saveSearchText",
        searchText,
      });
    },
  },
};
