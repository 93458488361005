import React, { useEffect, useState } from "react";
import { connect } from "dva";
import { Text, Button, Loading, Input } from "@geist-ui/react";
import { Link } from "react-router-dom";

import PodcastThumb from "../PodcastThumb";
import { MdSearch } from "react-icons/md";
import Masonry from "react-masonry-css";
import styles from "./index.module.css";
import * as _ from "lodash";

function Browse({ dispatch, podcast, loading, history }) {
  const [searchText, setSearchText] = useState("");
  const onSearch = () => {
    if (searchText.length) {
      if (searchText.includes("://")) {
        history.push(`/podcast/${btoa(searchText)}`);
      } else {
        dispatch({
          type: "podcast/searchPodcasts",
          searchQuery: searchText,
        });
      }
    } else {
      dispatch({
        type: "podcast/clearSearchPodcasts",
      });
    }
  };
  const { podcastSearchItems, podcastSearchText } = podcast;
  const { results, resultCount } = podcastSearchItems;

  const onClickPodcast = () => {};
  return (
    <div className={styles.container}>
      <div className={styles.podcastTitle}>
        <Text h1>Browse</Text>

        <Input
          iconRight={<MdSearch />}
          placeholder="Search or Paste Feed URL"
          clearable
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSearch(e.target.value);
            }
          }}
          onBlur={() => onSearch}
        />
      </div>

      {resultCount ? (
        <>
          <Text h4>
            Showing {resultCount} results for "{podcastSearchText}"
          </Text>
          <Masonry
            breakpointCols={{
              default: 5,
              1600: 4,
              768: 3,
              480: 1,
            }}
            className={styles.podcastList}
            columnClassName={styles.podcastColumn}
          >
            {results.map((p, pi) => {
              return (
                <PodcastThumb
                  key={`${p.trackName}-${pi}`}
                  podcastData={{
                    image: p.artworkUrl600,
                    title: p.trackName,
                    author: p.artistName,
                    episodeCount: p.trackCount,
                    feedUrl: p.feedUrl,
                  }}
                  onClick={onClickPodcast}
                />
              );
            })}
          </Masonry>
        </>
      ) : (
        <div className={styles.empty}>
          {loading.effects["podcast/searchPodcasts"] ? (
            <Loading size="large" />
          ) : searchText.length && searchText === podcastSearchText ? (
            <Text p>No results found for "{searchText}"</Text>
          ) : (
            <Text p>Search for podcasts using the search bar</Text>
          )}
        </div>
      )}
    </div>
  );
}

export default connect(({ podcast, loading }) => ({
  podcast,
  loading,
}))(Browse);
