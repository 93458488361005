import { CssBaseline, GeistProvider } from "@geist-ui/react";
import dva from "dva";
import createLoading from "dva-loading";
import React from "react";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import App from "./App";
import sonicTheme from "./config/theme";
import "./index.css";
import models from "./models";
import reportWebVitals from "./reportWebVitals";
import localForage from "localforage";

const key = "dva";

const app = dva();
function storageEnhancer(opts = { key, storage }) {
  return (createStore) => (reducer, initialState, enhancer) => {
    const options = { key, storage, ...opts };
    const store = createStore(
      persistReducer(options, reducer),
      initialState,
      enhancer
    );
    const persistor = persistStore(store);
    return { ...store, persistor };
  };
}

app.use(createLoading());
app.use({
  extraEnhancers: [
    storageEnhancer({
      storage: localForage,
    }),
  ],
});

models.map((model) => app.model(model));

app.router(() => (
  // <React.StrictMode>
  <GeistProvider theme={sonicTheme}>
    <CssBaseline />
    <App />
  </GeistProvider>
  // </React.StrictMode>
));

app.start("#root");

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
