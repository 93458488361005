import React, { useState, useEffect } from "react";
import { Button, Text } from "@geist-ui/react";
import { Link } from "react-router-dom";
import Logo from "../../assets/svg/logo.svg";

function Navigation() {
  const [currentLocation, setCurrentLocation] = useState(
    window.location.pathname
  );

  return (
    <div className="navigation">
      <div className="logo">
        <img src={Logo} className="logo-image" />
        <Text h2 className="logo-text">
          Sonic
        </Text>
      </div>
      <div className="nav-list">
        <Link to="/">
          <Button
            className="nav-item"
            auto
            onClick={() => setCurrentLocation("/")}
            type={currentLocation === "/" ? "secondary" : undefined}
          >
            My Podcasts
          </Button>
        </Link>
        <Link to="/playlist">
          <Button
            className="nav-item"
            auto
            onClick={() => setCurrentLocation("/playlist")}
            type={currentLocation === "/playlist" ? "secondary" : undefined}
          >
            Playlist
          </Button>
        </Link>
        <Link
          to="/browse"
          type={currentLocation === "/browse" ? "secondary" : undefined}
        >
          <Button
            className="nav-item"
            auto
            onClick={() => setCurrentLocation("/browse")}
          >
            Browse
          </Button>
        </Link>
        {/* <Link to="/favourites">
            <Button className="nav-item">Favourites</Button>
          </Link>
          <Link to="/downloads">
            <Button className="nav-item">Downloads</Button>
          </Link>
          <Link to="/settings">
            <Button className="nav-item">Settings</Button>
          </Link> */}
      </div>
      <style jsx>
        {`
          .navigation {
            display: flex;
            max-width: 15vw;
            flex-shrink: 0;
            flex-direction: column;
            padding: 15px;
            border-right: 1px solid black;
          }
          .logo {
            height: 50px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .logo-text {
            margin: 0;
          }
          .logo-image {
            width: 50px;
          }
          .nav-list {
            display: flex;
            flex-direction: column;
            justify-self: center;
            margin-bottom: auto;
            margin-top: 1rem;
          }
          .nav-item {
            margin-bottom: 15px;
            // background: #e6e6e6;
            // box-shadow: 4px 4px 8px #c4c4c4, -4px -4px 8px #ffffff !important;
          }
          button.nav-item {
            width: 100% !important;
          }
        `}
      </style>
    </div>
  );
}

export default Navigation;
