import React, { useState } from "react";
import { MdSearch } from "react-icons/md";
import { Input } from "@geist-ui/react";
import { connect } from "dva";
import * as _ from "lodash";

function Header({ dispatch, onSearchChange }) {
  const debouncedOnSearchChange = _.debounce(onSearchChange, 250);
  return (
    <div className="header">
      <div className="search-bar">
        <Input
          iconRight={<MdSearch />}
          placeholder="Search"
          width="100%"
          clearable
          onChange={(e) => {
            debouncedOnSearchChange(e.target.value);
          }}
        />
      </div>
      <div className="user"></div>
      <style jsx>
        {`
          .header {
            display: flex;
            padding: 2em 0 1rem 0;
          }
          .search-bar {
            flex-grow: 1;
          }
          .user {
            flex-grow: 0.75;
          }
        `}
      </style>
    </div>
  );
}

export default connect(({ podcast }) => ({
  podcast,
}))(Header);
