import React from "react";
import { Image, Text, Card } from "@geist-ui/react";
import { Link } from "react-router-dom";
import { getHash } from "../../utils";
import styles from "./index.module.css";
function PodcastThumb({ podcastData }) {
  const { image, title, author, episodeCount, feedUrl } = podcastData;
  return (
    <Link to={`/podcast/${getHash(feedUrl)}`} className={styles.thumbLink}>
      <Card hoverable className={styles.thumbContainer}>
        <Image src={image} className={styles.image} />
        <Card.Content>
          <div className={styles.title}>{title}</div>
          <div className={styles.artist}>{author}</div>
          <div className={styles.episodeCount}>{episodeCount} Episodes</div>
        </Card.Content>
      </Card>
    </Link>
  );
}

export default PodcastThumb;
