import {
  Button,
  Image,
  Loading,
  Modal,
  Text,
  useModal,
  ButtonGroup,
} from "@geist-ui/react";
import { ExternalLink } from "@geist-ui/react-icons";
import { connect } from "dva";
import parse from "html-react-parser";
import * as _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  MdAddCircle,
  MdCheckCircle,
  MdPlayCircleOutline,
  MdPlaylistAdd,
  MdArrowUpward,
  MdArrowDownward,
} from "react-icons/md";
import { useParams } from "react-router-dom";
import styles from "./index.module.css";

import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
function Podcast({ podcast, loading, searchText, dispatch, history }) {
  const {
    currentPodcast,
    currentPodcastHash,
    podcastFeeds,
    playlist,
    currentEpisode,
  } = podcast;
  const { setVisible, bindings } = useModal();
  const [episode, setEpisode] = useState({});
  const { podcastId } = useParams();
  const getFeedUrl = (hash) => {
    return atob(podcastId);
  };
  const isSubscribed = _.includes(podcastFeeds, getFeedUrl(podcastId));
  const [filteredEpisodes, setFilteredEpisodes] = useState(
    currentPodcast.items
  );
  const [sortType, setSortType] = useState("release");
  const [direction, setDirection] = useState(true);

  const onClickSort = (type) => {
    if (type === sortType) {
      setDirection(!direction);
    } else {
      setSortType(type);
      setDirection(false);
    }
  };
  useEffect(() => {
    if (currentPodcast?.items) {
      const sortedList = currentPodcast.items.sort(function (a, b) {
        if (sortType === "title") {
          return ("" + a.title).localeCompare(b.title);
        } else if (sortType === "release") {
          return moment(a.isoDate).unix() - moment(b.isoDate).unix();
        }
      });
      setFilteredEpisodes(sortedList);
    }
  }, [currentPodcast.items, sortType]);

  useEffect(() => {
    setFilteredEpisodes(_.reverse(filteredEpisodes));
  }, [direction]);

  useEffect(() => {
    if (podcastId !== currentPodcastHash) {
      dispatch({
        type: "podcast/browsePodcast",
        feedUrl: getFeedUrl(podcastId),
      });
    }
  }, [podcastId, currentPodcastHash]);

  const getPlaylistIndex = (e) => {
    // if present returns index, else return -1
    return _.findIndex(
      playlist,
      (epi) => epi.title === e.title && e.pubDate === epi.pubDate
    );
  };
  const onClickEpisode = (episode) => {
    setEpisode(episode);
    setVisible(true);
  };
  const onPlayEpisode = (episode) => {
    dispatch({
      type: "podcast/playEpisode",
      episode: {
        ...episode,
        podcast: getFeedUrl(podcastId),
        podcastImage: currentPodcast.itunes.image,
        podcastTitle: currentPodcast.title,
      },
    });
  };
  const onAddEpisodeToPlaylist = (episode) => {
    dispatch({
      type: "podcast/addEpisodeToPlaylist",
      episode: {
        ...episode,
        podcast: getFeedUrl(podcastId),
        podcastImage: currentPodcast.itunes.image,
        podcastTitle: currentPodcast.title,
      },
    });
  };
  const onSubscribe = () => {
    dispatch({
      type: "podcast/addNewPodcast",
      feed: getFeedUrl(podcastId),
    });
  };
  const onUnSubscribe = () => {
    dispatch({
      type: "podcast/removePodcast",
      feed: getFeedUrl(podcastId),
    });
  };
  const Row = ({ index, style }) => {
    const e = currentPodcast.items[index];
    const playlistIndex = getPlaylistIndex(e);
    return (
      <div
        className={styles.episode}
        style={style}
        key={e.guid}
        onClick={() => onClickEpisode(e)}
      >
        <Image
          width={108}
          height={108}
          src={e.itunes.image ? e.itunes.image : currentPodcast.itunes.image}
          className={styles.image}
        />
        <div className={styles.episodeMeta}>
          <div className={styles.pubDate}>{moment(e.isoDate).fromNow()}</div>
          <div className={styles.episodeTitle}>{e.title}</div>
          <div className={styles.episodeSummary}>{e.contentSnippet}</div>
          <div className={styles.episodeActions}>
            <Button
              size="small"
              icon={<MdPlayCircleOutline />}
              auto
              onClick={(event) => {
                event.stopPropagation();
                onPlayEpisode(e);
              }}
              type={playlistIndex === currentEpisode ? "success" : undefined}
              ghost
            >
              {playlistIndex === currentEpisode
                ? "Playing"
                : `Play ${e.itunes.duration ? e.itunes.duration : ""}`}
            </Button>
            <Button
              size="small"
              icon={<MdPlaylistAdd />}
              auto
              onClick={(event) => {
                event.stopPropagation();
                onAddEpisodeToPlaylist(e);
              }}
              disabled={playlistIndex !== -1}
            >
              {playlistIndex !== -1 ? "In playlist" : "Add to Playlist"}
            </Button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={styles.container}>
      {podcastId === currentPodcastHash &&
      !loading.effects["podcast/browsePodcast"] &&
      currentPodcast &&
      currentPodcast.itunes ? (
        <>
          <div className={styles.podcastHeader}>
            <Image
              width={144}
              height={144}
              src={currentPodcast.itunes.image}
              className={styles.image}
            />

            <div className={styles.podcastMeta}>
              <div className={styles.title}>{currentPodcast.title}</div>
              <div className={styles.subtitle}>
                <div className={styles.artist}>
                  By {currentPodcast.itunes.author}
                </div>
                <div className={styles.episodeCount}>
                  {currentPodcast.items.length} Episodes
                </div>
                <div className={styles.lastUpdated}>
                  Updated {moment(currentPodcast.lastUpdatedAt).fromNow()}
                </div>
              </div>

              <div className={styles.summary}>
                {currentPodcast.itunes.summary}
              </div>
              <div className={styles.actions}>
                <Button
                  size="small"
                  icon={isSubscribed ? <MdCheckCircle /> : <MdAddCircle />}
                  auto
                  onClick={isSubscribed ? onUnSubscribe : onSubscribe}
                  type={isSubscribed ? "success" : "default"}
                  loading={
                    loading.effects["podcast/addNewPodcast"] ||
                    loading.effects["podcast/removePodcast"]
                  }
                >
                  {isSubscribed ? "Subscribed" : "Subscribe"}
                </Button>
                <a href={currentPodcast.link} target="_blank">
                  <Button size="small" icon={<ExternalLink />} auto>
                    Visit Website
                  </Button>
                </a>
              </div>
            </div>
          </div>
          <div className={styles.listHeader}>
            <Text h3>Episodes</Text>
            <div className={styles.listSort}>
              <Text small>Sort by</Text>
              <ButtonGroup size="mini">
                <Button
                  onClick={() => onClickSort("release")}
                  iconRight={
                    sortType === "release" ? (
                      direction ? (
                        <MdArrowUpward />
                      ) : (
                        <MdArrowDownward />
                      )
                    ) : undefined
                  }
                  type="success"
                >
                  Release Date
                </Button>
                <Button
                  onClick={() => onClickSort("title")}
                  iconRight={
                    sortType === "title" ? (
                      direction ? (
                        <MdArrowUpward />
                      ) : (
                        <MdArrowDownward />
                      )
                    ) : undefined
                  }
                >
                  Title
                </Button>
              </ButtonGroup>
            </div>
          </div>
          <div className={styles.episodes} key={`${sortType}-${direction}`}>
            <AutoSizer>
              {({ height, width }) => (
                <List
                  className={styles.listContent}
                  height={height}
                  width={width}
                  itemCount={currentPodcast.items.length}
                  itemSize={140}
                >
                  {Row}
                </List>
              )}
            </AutoSizer>
          </div>
        </>
      ) : (
        <div className="empty">
          <Loading />
        </div>
      )}
      <Modal width="60vw" {...bindings}>
        <Modal.Title>{episode.title}</Modal.Title>
        {episode.title ? (
          <Modal.Content className={styles.modalContent}>
            <div className={styles.modalActions}>
              <Image
                width={168}
                height={168}
                src={
                  _.get(episode, "episode.itunes.image")
                    ? episode.itunes.image
                    : currentPodcast.itunes.image
                }
                className={styles.image}
              />
              <Button
                icon={<MdPlayCircleOutline />}
                auto
                type={episode === currentEpisode ? "success" : undefined}
                ghost
                onClick={(event) => {
                  event.stopPropagation();
                  onPlayEpisode(episode);
                }}
              >
                Play {episode.itunes.duration ? episode.itunes.duration : null}
              </Button>
              <Button icon={<MdPlaylistAdd />} auto>
                Add to Playlist
              </Button>
            </div>

            <div className={styles.modalMeta}>
              <div className={styles.modalPublish}>
                Published on {moment(episode.pubDate).format("LLL")}
              </div>
              <div className={styles.modalSummary}>
                {episode["content:encoded"]
                  ? parse(episode["content:encoded"])
                  : parse(episode.content)}
              </div>
            </div>
          </Modal.Content>
        ) : null}
      </Modal>
    </div>
  );
}

export default connect(({ podcast, loading }) => ({
  podcast,
  loading,
}))(Podcast);
