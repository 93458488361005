import React, { useEffect, useState } from "react";
import { connect } from "dva";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import "./App.css";
import { Button } from "@geist-ui/react";

import Player from "./components/Player";
import Navigation from "./components/Navigation";
import MyPodcasts from "./components/MyPodcasts";
import Browse from "./components/Browse";
import Podcast from "./components/Podcast";
import Playlist from "./components/Playlist";
import Header from "./components/Header";

const App = ({ dispatch, podcast }) => {
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    dispatch({
      type: "ui/initialize",
    });
  }, [dispatch]);
  return (
    <Router>
      <div className="app-container">
        <Navigation />
        <div className="content-container">
          <Switch>
            <Route path="/playlist" component={Playlist} />
            <Route path="/browse" component={Browse} />
            <Route path="/podcast/:podcastId" component={Podcast} />
            <Route path="/favourites">
              <div>Favourites</div>
            </Route>
            <Route path="/downloads">
              <div>Downloads</div>
            </Route>
            <Route path="/settings">
              <div>Settings</div>
            </Route>{" "}
            <Route path="/">
              <MyPodcasts searchText={searchText} />
            </Route>
          </Switch>
        </div>
        <Player />
        <style jsx>
          {`
            .app-container {
              display: flex;
              max-height: 100vh;
              height: 100%;
            }

            .content-container {
              flex-grow: 1;
              margin: 0 2em !important;
              display: flex;
              flex-direction: column;

              overflow: hidden;
              padding-top: 2rem;
            }
          `}
        </style>
      </div>
    </Router>
  );
};

export default connect(({ podcast }) => ({
  podcast,
}))(App);
