import { Button, Image, Modal, Text, useModal } from "@geist-ui/react";
import { connect } from "dva";
import parse from "html-react-parser";
import * as _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { MdPlayCircleOutline, MdRemoveCircle } from "react-icons/md";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import styles from "./index.module.css";
function Playlist({ podcast, loading, searchText, dispatch, history }) {
  const {
    currentPodcast,
    currentPodcastHash,
    podcastFeeds,
    playlist,
    currentEpisode,
    podcasts,
  } = podcast;
  const { setVisible, bindings } = useModal();
  const [episode, setEpisode] = useState({});
  const { podcastId } = useParams();

  useEffect(() => {}, []);
  const onClickEpisode = (episode) => {
    setEpisode(episode);
    setVisible(true);
  };

  const onPlayEpisode = (episode) => {
    dispatch({
      type: "podcast/playEpisode",
      episode: {
        ...episode,
        podcast: episode.podcast,
      },
    });
  };
  const onClearPlaylist = () => {
    dispatch({
      type: "podcast/cleanPlaylist",
    });
  };
  const onRemoveFromPlaylist = (playlistIndex) => {
    dispatch({
      type: "podcast/removeEpisodeFromPlaylist",
      playlistIndex,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Text h1>Playlist</Text>
        <Button onClick={onClearPlaylist} disabled={!playlist.length}>
          Clear Playlist
        </Button>
      </div>
      {playlist.length ? (
        <>
          <div className={styles.listHeader}>
            <Text h3>Episodes</Text>
            <div className={styles.listSort}></div>
          </div>
          <div className={styles.episodes}>
            <div className={styles.listContent}>
              {playlist.length
                ? playlist.map((e, playlistIndex) => {
                    return (
                      <div className={styles.episode} key={e.guid}>
                        <Image
                          width={48}
                          height={48}
                          src={e.itunes.image ? e.itunes.image : e.podcastImage}
                          className={styles.image}
                        />
                        <div className={styles.episodeMeta}>
                          <div className={styles.pubDate}>
                            {moment(e.isoDate).fromNow()}
                          </div>
                          <div
                            className={styles.episodeTitle}
                            onClick={() => onClickEpisode(e)}
                          >
                            {e.title}
                          </div>
                          <Link to={`/podcast/${btoa(e.podcast)}`}>
                            <div className={styles.episodeSummary}>
                              {e.podcastTitle}
                            </div>
                          </Link>
                        </div>
                        <div className={styles.episodeActions}>
                          <Button
                            icon={<MdPlayCircleOutline />}
                            auto
                            type={
                              playlistIndex === currentEpisode
                                ? "success"
                                : undefined
                            }
                            ghost
                            onClick={(event) => {
                              event.stopPropagation();
                              onPlayEpisode(e);
                            }}
                          >
                            {playlistIndex === currentEpisode
                              ? "Playing"
                              : `Play ${
                                  e.itunes.duration
                                    ? new Date(
                                        parseInt(e.itunes.duration) * 1000
                                      )
                                        .toISOString()
                                        .substr(11, 8)
                                    : ""
                                }`}
                          </Button>
                          <Button
                            icon={<MdRemoveCircle />}
                            auto
                            onClick={(event) => {
                              event.stopPropagation();
                              onRemoveFromPlaylist(playlistIndex);
                            }}
                          >
                            Remove
                          </Button>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </>
      ) : (
        <div className="empty">
          <Text>Your playlist looks empty add some podcasts</Text>
        </div>
      )}
      <Modal width="60vw" {...bindings}>
        <Modal.Title>{episode.title}</Modal.Title>
        {episode.title ? (
          <Modal.Content className={styles.modalContent}>
            <div className={styles.modalActions}>
              <Image
                width={168}
                height={168}
                src={
                  _.get(episode, "episode.itunes.image")
                    ? episode.itunes.image
                    : episode.podcastImage
                }
                className="image"
              />
              <Button icon={<MdPlayCircleOutline />} auto>
                Play{" "}
                {episode.itunes.duration
                  ? new Date(parseInt(episode.itunes.duration) * 1000)
                      .toISOString()
                      .substr(11, 8)
                  : null}
              </Button>
              <Button icon={<MdRemoveCircle />} auto>
                Open Podcast
              </Button>
            </div>

            <div className={styles.modalMeta}>
              <div className={styles.modalPublish}>
                Published on {moment(episode.pubDate).format("LLL")}
              </div>
              <div className={styles.modalSummary}>
                {episode["content:encoded"]
                  ? parse(episode["content:encoded"])
                  : parse(episode.content)}
              </div>
            </div>
          </Modal.Content>
        ) : null}
      </Modal>
    </div>
  );
}

export default connect(({ podcast, loading }) => ({
  podcast,
  loading,
}))(Playlist);
