const sonicTheme = {
  layout: {
    radius: "24px",
  },
  palette: {
    foreground: "#000000",
    secondary: "#323232",
  },
};
export default sonicTheme;
