const initialState = {
  playingEpisodeId: -1,
  episodeData: false,
  sliderValue: 0,
  volume: 1,
  playing: false,
  muted: false,
  duration: 0,
  progress: { played: 0, playedSeconds: 0, loaded: 0, loadedSeconds: 0 },
  player: null,
  buffering: false,
  playbackRate: 1,
};

export default {
  namespace: "player",
  state: { ...initialState },
  reducers: {
    updatePlayerParam(state, payload) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *setPlayingEpisodeId({ playingEpisodeId }, { call, put }) {
      yield put({
        type: "updatePlayerParam",
        playingEpisodeId,
      });
    },
    *setEpisodeData({ episodeData }, { call, put }) {
      yield put({
        type: "updatePlayerParam",
        episodeData,
      });
    },
    *setSliderValue({ sliderValue }, { call, put }) {
      yield put({
        type: "updatePlayerParam",
        sliderValue,
      });
    },
    *setVolume({ volume }, { call, put }) {
      yield put({
        type: "updatePlayerParam",
        volume,
      });
    },
    *setPlay({ playing }, { call, put }) {
      yield put({
        type: "updatePlayerParam",
        playing,
      });
    },
    *setMute({ muted }, { call, put }) {
      yield put({
        type: "updatePlayerParam",
        muted,
      });
    },
    *setDuration({ duration }, { call, put }) {
      yield put({
        type: "updatePlayerParam",
        duration,
      });
    },
    *setProgress({ progress }, { call, put }) {
      yield put({
        type: "updatePlayerParam",
        progress,
      });
    },
    *setPlayer({ player }, { call, put }) {
      yield put({
        type: "updatePlayerParam",
        player,
      });
    },
    *setBuffering({ buffering }, { call, put }) {
      yield put({
        type: "updatePlayerParam",
        buffering,
      });
    },
    *setPlaybackRate({ playbackRate }, { call, put }) {
      yield put({
        type: "updatePlayerParam",
        playbackRate,
      });
    },
  },
};
