const fetch = require("node-fetch");

const baseUrl = "https://itunes.apple.com/search/";

export const rssApi = async (query) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${baseUrl}?media=podcast&term=${query}`, {})
        .then((res) => res.json())
        .then((json) => {
          resolve(json);
        });
    } catch (error) {
      reject(error);
    }
  });
};
