let Parser = require("rss-parser");
let parser = new Parser({});

const getFoolProofUrl = (url_) => {
  let url = new URL(url_);
  url.searchParams.append("format", "xml");
  const data = `https://sonic-backend.arul.one/${url.toString()}`;
  return data;
};

export const getRSSData = async (url) => {
  try {
    let feed = await parser.parseURL(getFoolProofUrl(url));
    return feed;
  } catch (e) {
    console.error(e);
    return {};
  }
};
// const fetch = require("node-fetch");

// export const getRSSData = async (url) => {
//   try {
//     fetch(`${url}?format=xml`, {})
//       .then((response) => response.text())
//       .then((str) => new window.DOMParser().parseFromString(str, "text/xml"))
//       .then((data) => console.log(data));
//   } catch (e) {
//     console.error(e);
//     return {};
//   }
// };
