import { Button, Image, Text, Popover, Radio } from "@geist-ui/react";
import { connect } from "dva";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  MdForward10,
  MdPlayCircleFilled,
  MdReplay10,
  MdSkipNext,
  MdSkipPrevious,
  MdFavoriteBorder,
  MdVolumeOff,
  MdVolumeDown,
  MdVolumeUp,
  MdPauseCircleFilled,
} from "react-icons/md";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import parse from "html-react-parser";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

import styles from "./index.module.css";

const markStyle = {
  fontSize: "1rem",
  paddingTop: 5,
  color: "#323232",
  fontWeight: 500,
};
const sliderProps = {
  railStyle: {
    height: 10,
    backgroundColor: "#939393",
  },
  trackStyle: [
    {
      backgroundColor: "#323232",
      height: 10,
    },
  ],
  handleStyle: [
    {
      height: 20,
      width: 20,
      border: "none",
      color: "white",
    },
  ],
  dotStyle: {
    display: "none",
  },
};
const Player = ({ podcast, player, dispatch }) => {
  const [playerRef, setPlayerRef] = useState(null);
  const {
    playingEpisodeId,
    episodeData,
    sliderValue,
    volume,
    playing,
    muted,
    duration,
    buffering,
    playbackRate,
    progress,
  } = player;

  const { currentEpisode, podcasts, playlist } = podcast;
  const playingEpisode = playlist[currentEpisode];
  const [speedVisible, setSpeedVisible] = useState(false);
  const setPlayingEpisodeId = (playingEpisodeId) => {
    dispatch({
      type: "player/setPlayingEpisodeId",
      playingEpisodeId,
    });
  };
  const setEpisodeData = (episodeData) => {
    dispatch({
      type: "player/setEpisodeData",
      episodeData,
    });
  };
  const setSliderValue = (sliderValue) => {
    dispatch({
      type: "player/setSliderValue",
      sliderValue,
    });
  };
  const setVolume = (volume) => {
    dispatch({
      type: "player/setVolume",
      volume,
    });
  };
  const setPlay = (playing) => {
    dispatch({
      type: "player/setPlay",
      playing,
    });
  };
  const setMute = (muted) => {
    dispatch({
      type: "player/setMute",
      muted,
    });
  };
  const setDuration = (duration) => {
    dispatch({
      type: "player/setDuration",
      duration,
    });
  };
  const setProgress = (progress) => {
    dispatch({
      type: "player/setProgress",
      progress,
    });
  };
  const setBuffering = (buffering) => {
    dispatch({
      type: "player/setBuffering",
      buffering,
    });
  };
  const setPlaybackRate = (playbackRate) => {
    dispatch({
      type: "player/setPlaybackRate",
      playbackRate,
    });
  };
  useEffect(() => {
    if (podcast.currentEpisode !== playingEpisodeId) {
      setPlayingEpisodeId(podcast.currentEpisode);
      setEpisodeData(podcast.playlist[podcast.currentEpisode]);
      setPlay(false);
    }
  });
  useEffect(() => {
    setPlay(false);
  }, []);
  const handleSlider = (value) => {
    setSliderValue(value);
    playerRef.seekTo(value);
  };

  const handleProgress = (progress) => {
    setSliderValue(progress.playedSeconds);
    setProgress(progress);
  };

  const handleSeek = (direction) => handleSlider(sliderValue + direction * 10);
  // useEffect(() => {
  //   setPlay(true);
  //   // handleSlider(progress);
  // }, [progress, handleSlider, setPlay]);
  return playingEpisode ? (
    <div id="player" className={styles.playerContainer}>
      <ReactPlayer
        ref={setPlayerRef}
        url={episodeData ? episodeData.enclosure.url : ""}
        playing={playing}
        volume={volume}
        muted={muted}
        playsinline={true}
        controls={false}
        width={0}
        height={0}
        onDuration={setDuration}
        onProgress={handleProgress}
        onPlay={() => setPlay(true)}
        onPause={() => setPlay(false)}
        onBuffer={() => setBuffering(true)}
        onBufferEnd={() => setBuffering(false)}
        playbackRate={playbackRate}
      />
      <div className={styles.playerMeta}>
        <div className={styles.playerImage}>
          <Image
            width={"50%"}
            height={"50%"}
            src={
              _.get(playingEpisode, "itunes.image")
                ? playingEpisode.itunes.image
                : playingEpisode.podcastImage
            }
            className="image"
          />
        </div>
        <Text h3 className={styles.playerTitle}>
          {playingEpisode.title}
        </Text>
        <Link to={`/podcast/${btoa(playingEpisode.podcast)}`}>
          <Text h5 className={styles.playerSubtitle}>
            {playingEpisode.podcastTitle}
          </Text>
        </Link>
      </div>
      <div className={styles.playerSummary}>
        {playingEpisode["content:encoded"]
          ? parse(playingEpisode["content:encoded"])
          : parse(playingEpisode.content)}
      </div>
      <div className={styles.playerMainControls}>
        <Button
          iconRight={<MdSkipPrevious />}
          auto
          size="small"
          className={styles.smallBtn}
        />
        <Button
          iconRight={<MdReplay10 />}
          auto
          size="small"
          className={styles.smallBtn}
          onClick={() => handleSeek(-1)}
        />
        <Button
          iconRight={playing ? <MdPauseCircleFilled /> : <MdPlayCircleFilled />}
          auto
          size="small"
          className={styles.bigBtn}
          onClick={() => {
            setPlay(!playing);
          }}
        />
        <Button
          iconRight={<MdForward10 />}
          auto
          size="small"
          className={styles.smallBtn}
          onClick={() => handleSeek(1)}
        />
        <Button
          iconRight={<MdSkipNext />}
          auto
          size="small"
          className={styles.smallBtn}
        />
      </div>
      <div className={styles.playerSeeker}>
        <Slider
          showMarkers={true}
          min={0}
          max={duration}
          value={sliderValue}
          marks={{
            0: {
              label: buffering
                ? "Buffering"
                : new Date(sliderValue * 1000).toISOString().substr(11, 8),
              style: markStyle,
            },
            [duration]: {
              label: new Date(duration * 1000).toISOString().substr(11, 8),
              style: markStyle,
            },
          }}
          onChange={handleSlider}
          {...sliderProps}
        />
      </div>
      <div className={styles.playerAuxControls}>
        <Button
          iconRight={<MdFavoriteBorder />}
          auto
          size="small"
          className={styles.smallerBtn}
        />
        <Popover
          content={
            <Popover.Item>
              <Radio.Group
                value={playbackRate}
                onChange={(val) => {
                  setPlaybackRate(val);
                  setSpeedVisible(false);
                }}
              >
                <Radio value={0.5}>0.5x</Radio>
                <Radio value={0.8}>0.8x</Radio>
                <Radio value={1}>1x</Radio>
                <Radio value={1.2}>1.2x</Radio>
                <Radio value={1.5}>1.5x</Radio>
                <Radio value={2}>2x</Radio>
              </Radio.Group>
            </Popover.Item>
          }
          placement="top"
          portalClassName={styles.playbackSelector}
          visible={speedVisible}
          onVisibleChange={(val) => setSpeedVisible(val)}
        >
          <Button auto size="small" className={styles.smallerBtn}>
            {playbackRate}x
          </Button>
        </Popover>

        <Button
          iconRight={
            muted ? (
              <MdVolumeOff />
            ) : volume > 0.5 ? (
              <MdVolumeUp />
            ) : (
              <MdVolumeDown />
            )
          }
          auto
          size="small"
          className={styles.smallerBtn}
          onClick={() => {
            setMute(!muted);
          }}
        />
        <Slider
          className={styles.volumeSlider}
          min={0}
          max={1}
          step={0.1}
          value={volume}
          onChange={(value) => setVolume(value)}
          {...sliderProps}
        />
      </div>
    </div>
  ) : null;
};
export default connect(({ podcast, player }) => ({
  podcast,
  player,
}))(Player);
