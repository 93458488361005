import React, { useEffect, useState } from "react";
import { connect } from "dva";
import { Text, Button, Loading, Input } from "@geist-ui/react";
import { Link } from "react-router-dom";

import PodcastThumb from "../PodcastThumb";
import { MdSearch } from "react-icons/md";
import Masonry from "react-masonry-css";
import styles from "./index.module.css";
import * as _ from "lodash";

function MyPodcasts({ dispatch, podcast, loading, history }) {
  const { podcasts, podcastFeeds } = podcast;
  const [searchText, setSearchText] = useState("");
  const [filteredFeeds, setFilteredFeeds] = useState(podcastFeeds);

  const textFilter = (searchText, value) => {
    if (!value) return false;
    return (
      value.toLowerCase &&
      value.toLowerCase().indexOf(searchText.toLowerCase()) + 1
    );
  };
  useEffect(() => {
    if (searchText.length) {
      const _filteredFeeds = podcastFeeds.filter((p) => {
        const podcast = podcasts[p];
        return [
          "title",
          "description",
          "itunes.owner.name",
          "itunes.subtitle",
          "itunes.summary",
        ].some((key) => {
          return textFilter(searchText, _.get(podcast, key));
        });
      });
      setFilteredFeeds(_filteredFeeds);
    } else {
      setFilteredFeeds(podcastFeeds);
    }
  }, [podcastFeeds, searchText]);

  const onClickPodcast = () => {};
  return (
    <div className={styles.container}>
      <div className={styles.podcastTitle}>
        <Text h1>My Podcasts</Text>

        <Input
          iconRight={<MdSearch />}
          placeholder="Search"
          clearable
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
      </div>

      {filteredFeeds.length ? (
        <>
          <Masonry
            breakpointCols={{
              default: 5,
              1600: 4,
              768: 3,
              480: 1,
            }}
            className={styles.podcastList}
            columnClassName={styles.podcastColumn}
          >
            {filteredFeeds.map((p, pi) => {
              const pData = podcasts[p];
              return (
                <PodcastThumb
                  key={pi}
                  podcastData={{
                    image: pData.itunes.image,
                    title: pData.title,
                    author: pData.itunes.author,
                    episodeCount: pData.items.length,
                    feedUrl: p,
                  }}
                  onClick={onClickPodcast}
                />
              );
            })}
          </Masonry>
        </>
      ) : (
        <div className={styles.empty}>
          {loading.effects["podcast/searchPodcasts"] ? (
            <Loading size="large" />
          ) : (
            <Link to={styles.browse}>
              <Button className="nav-item">Browse Podcasts</Button>
            </Link>
          )}
        </div>
      )}
    </div>
  );
}

export default connect(({ podcast, loading }) => ({
  podcast,
  loading,
}))(MyPodcasts);
